import fmoClient from "./request"

const getMaterials = async (params) => {
	return await fmoClient.get('/materials-manager', { params } );
};

const importMaterial = async (formData) => {
	return await fmoClient.post('/materials-manager/upload', formData);
};

const truncateMaterials = async () => {
	return await fmoClient.delete('materials-manager/truncate')
}

const exportMaterials = async (params) => {
	return await fmoClient.get('materials-manager/export/reports', { params })
}

const getMaterialLogs = async (params) => {
	return await fmoClient.get('/materials-manager/logs', { params })
}

const updateMaterialLog = async (params) => {
	return await fmoClient.put('/materials-manager/logs/update', params);
}

const deleteMaterialLog = async (id) => {
	return await fmoClient.delete(`materials-manager/logs/${id}`)
}

const decreaseMaterials = async (params) => {
	return await fmoClient.post('/materials-manager/decrease/materials', params);
}

const manualAddLog = async (payload) => {
	return await fmoClient.post('/materials-manager/logs/manualAdd', payload);
}

export default {
	getMaterials,
	importMaterial,
	truncateMaterials,
	exportMaterials,
	getMaterialLogs,
	updateMaterialLog,
	decreaseMaterials,
	deleteMaterialLog,
	manualAddLog,
}