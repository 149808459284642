import { userApis } from "@iso/lib/apis";
import { createBrowserHistory } from "history";
import { all, fork, put, takeEvery } from "redux-saga/effects";

import notifications from "@iso/components/Feedback/Notification";
import { clearToken, getToken } from "@iso/lib/helpers/utility";
import actions from "./actions";

import axios from "axios";

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    try {
      const response = yield axios.post(
        `${process.env.REACT_APP_FMO_BE_URL}/auth/login`,
        {
          user_name: payload.username,
          password: payload.password,
        }
      );
      const data = response.data;

      yield put({
        type: actions.LOGIN_SUCCESS,
        token: data.access_token,
        userData: data.user,
      });
    } catch (error) {
      yield put({
        type: actions.LOGIN_ERROR,
        errMessage: error.response?.data?.message || error.message,
      });
    }
  });
}

export function* larkLoginRequest() {
  yield takeEvery("LARK_LOGIN_REQUEST", function* (pyload) {
    const redirect_url = `${process.env.REACT_APP_FMO_BE_URL}/auth/lark`;
    console.log(redirect_url);
    window.location.href = redirect_url;
  })
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* (payload) {
    yield notifications.error({
      message: "Error",
      description: payload.errMessage,
    });
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    history.push("/");
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
      });
    }
  });
}
export function* getUserInformation() {
  yield takeEvery(actions.GET_USER_INFORMATION, function* () {
    try {
      const response = yield userApis.getCurrentUser();
      const data = response.data;
      yield put({
        type: actions.GET_USER_INFORMATION_SUCCESS,
        userData: data,
      });
    } catch (error) {
      yield put({
        type: actions.GET_USER_INFORMATION_ERROR,
        errMessage: error.response?.data?.message || error.message,
      });
    }
  });
}
export function* getUserInformationError() {
  yield takeEvery(actions.GET_USER_INFORMATION_ERROR, function* (payload) {
    yield notifications.error({
      message: "Error",
      description: payload.errMessage,
    });
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(larkLoginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(getUserInformation),
    fork(getUserInformationError),
  ]);
}
