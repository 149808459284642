import fmoClient from "./request";

const getStockCatalogs = async (params) => {
	return await fmoClient.get("/stock/catalogs", { params });
};

const createStockCatalogs = async (data) => {
	return await fmoClient.post("/stock/catalogs", data);
};

const getStockCatalogById = async (catalogId) => {
	return await fmoClient.get(`/stock/catalogs/${catalogId}`);
};

const updateStockCatalog = async (payload) => {
	return await fmoClient.put(`/stock/catalogs/${payload.catalogId}`, payload.data);
}

const deleteStockCatalog = async (catalogId) => {
	return await fmoClient.delete(`/stock/catalogs/${catalogId}`);
}

const deleteStockProd = async (catalogId) => {
	return await fmoClient.delete(`/stock/catalogs/product/${catalogId}`);
}

export default {
	getStockCatalogs,
	createStockCatalogs,
	getStockCatalogById,
	updateStockCatalog,
	deleteStockCatalog,
	deleteStockProd,
}