import fmoClient from "./request";

const getOrders = async (params) => {
  return await fmoClient.get("/orders", { params });
};

const getOrderById = async (orderId) => {
  return await fmoClient.get(`/orders/${orderId}`);
};

const createOrder = async (data) => {
  return await fmoClient.post("/orders", data);
};

const updateOrder = async ({ orderId, data }) => {
  return await fmoClient.put(`/orders/${orderId}`, data);
};

const importOrder = async (formData) => {
  return await fmoClient.post("/orders/upload", formData);
};

const deleteOrder = async (orderId) => {
  return await fmoClient.delete(`/orders/${orderId}`);
};

const getOrderOptions = async () => {
  return await fmoClient.get("/orders/options");
};

const getProducts = async () => {
  return await fmoClient.get("/orders/sku-products");
};

const exportOrders = async (params) => {
  return await fmoClient.get("/orders/export", { params });
};

const exportReportsOrders = async (params) => {
  return await fmoClient.get("/orders/export/reports", { params });
};

const updateOrderStatus = async (orderId, data) => {
  return await fmoClient.put(`/orders/${orderId}/update-status`, data);
};

const getOrderSummary = async (params) => {
  return await fmoClient.get("/orders/summary", { params });
};

const bulUpdateStatus = async (data) => {
  return await fmoClient.put("/orders/bulk-update/status", data);
};

const bulkDeleteOrders = async (data) => {
  return await fmoClient.post("/orders/bulk-delete/orders", data)
}

const combineShippingOrder = async (data) => {
  return await fmoClient.post("/orders/combine-orders/", data)
}

const getOrderByPrimaryId = async (orderPrimaryId) => {
  return await fmoClient.get(`/orders/primary/${encodeURIComponent(orderPrimaryId)}`);
};

const getDashboardSummary = async (params) => {
  return await fmoClient.get('/orders/dashboard', { params })
}

const getOrderTimeline = async (orderPrimaryId) => {
  return await fmoClient.get(`/orders/timeline/${encodeURIComponent(orderPrimaryId)}`);
}

export default {
  getOrders,
  getOrderById,
  createOrder,
  updateOrder,
  importOrder,
  deleteOrder,
  getOrderOptions,
  getProducts,
  exportOrders,
  updateOrderStatus,
  getOrderSummary,
  bulUpdateStatus,
  bulkDeleteOrders,
  combineShippingOrder,
  getOrderByPrimaryId,
  getDashboardSummary,
  exportReportsOrders,
  getOrderTimeline
};
