import { Map } from "immutable";
import {USER_ROLE} from "../constants/user";
import {ORDER_STATUS} from "../constants/order";

export function clearToken() {
  localStorage.removeItem("id_token");
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function formatCurrency(value) {
  if (value) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(value);
  }
}

export function formatVNCurrency(value) {
  if (value) {
    const formatter = new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    });
    return formatter.format(value);
  }
}

function generateRandomColor() {
  const baseColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${baseColor}`;
}

function generateLighterColor(baseColor, percentLighter) {
  const hex = baseColor.slice(1); // Remove the '#' character
  const rgb = parseInt(hex, 16);
  
  const r = (rgb >> 16) + percentLighter;
  const g = ((rgb >> 8) & 0xff) + percentLighter;
  const b = (rgb & 0xff) + percentLighter;
  
  const newColor = ((r << 16) | (g << 8) | b).toString(16);
  
  return `#${newColor.padStart(6, '0')}`;
}

export function genSellerData(chartData) {
  const percentLighter = 1; // Adjust this value for the desired lightness
  
  const labels = Object.keys(chartData);
  const datasets = [
    {
      data: Object.values(chartData),
      backgroundColor: labels.map(() => generateLighterColor(generateRandomColor(), percentLighter)),
      hoverBackgroundColor: labels.map(() => generateLighterColor(generateRandomColor(), percentLighter)),
    },
  ];
  return { labels, datasets };
}

export function genPayablesDashboashData(data){
  return  Object.entries(data).map(([key, value]) => {
    const genColor = generateRandomColor()
    return {
      label: key,
      data: Object.values(value),
      backgroundColor: generateLighterColor(genColor, 0.5),
      borderColor: generateLighterColor(genColor, 1),
      borderWidth: 1,
    }
  })
}

export const canUpdateStatus = (userRole, status) => {
  if (userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.SELLER || userRole === USER_ROLE.LEADER) {
    return ![ORDER_STATUS.CANCEL].includes(status);
  }
  if (userRole === USER_ROLE.MANUFACTURE) {
    return [
      ORDER_STATUS.DESIGN_CONFIRMED,
      ORDER_STATUS.PROCESSING,
      ORDER_STATUS.PRIORITY,
      ORDER_STATUS.APPROVED,
    ].includes(status);
  }
  return false;
};


export const remainListGroup = (data) => {
  let listGroup = []
  let subGroup = []
  let count = 0
  data.forEach((record) => {
    const sub_order_ind = record.order_primary_id.match(/-(\d+)$/);
    if (!sub_order_ind || !sub_order_ind[1]) {
      listGroup = [...listGroup, { order_primary_id: record.order_primary_id, rowSpan: 1 }]
      count = 0
      subGroup = []
    } else {
      if (sub_order_ind && sub_order_ind[1] && parseInt(sub_order_ind[1]) === 1) {
        let lastSpan = subGroup.pop()
        listGroup = [...listGroup, { ...lastSpan, rowSpan: ++count }, ...subGroup, { order_primary_id: record.order_primary_id, rowSpan: 0 }]
      } else {
        subGroup = [{ order_primary_id: record.order_primary_id, rowSpan: 0 }, ...subGroup]
        count++
      }
    }
  })
  if (data.length > listGroup.length) {
    listGroup = [...listGroup, { order_primary_id: null, rowSpan: data.length - listGroup.length }]
  }
  return listGroup
}

export const trimAllValues = (obj) => {
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (typeof value === 'string') {
      obj[key] = value.trim();
    } else if (typeof value === 'object' && value !== null) {
      trimAllValues(value); // Recursively trim nested objects
    }
  });
  return obj;
}

export const convertUTC2GMT7 = (utcTimeStr: string) => {
  const utcTime = new Date(utcTimeStr);
  const offset = 7 * 60;
  const gmt7Time = new Date(utcTime.getTime() + offset * 60 * 1000);
  return gmt7Time.toISOString()
}