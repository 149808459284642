const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LARK_LOGIN_REQUEST: "LARK_LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  GET_USER_INFORMATION: "GET_USER_INFORMATION",
  GET_USER_INFORMATION_SUCCESS: "GET_USER_INFORMATION_SUCCESS",
  GET_USER_INFORMATION_ERROR: "GET_USER_INFORMATION_ERROR",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload,
  }),
  larkLogin: () => ({
    type: actions.LARK_LOGIN_REQUEST
  }),
  larkLoginSuccess: (token) => ({
    type: actions.LOGIN_SUCCESS,
    token: token,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  getUserInformation: () => ({
    type: actions.GET_USER_INFORMATION,
  }),
};
export default actions;
